<footer>

  <div class="nav-wrap-100">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light bg-dark">
        <ul class="navbar navbar-expand-lg navbar-dark">
          <ul class="navbar-nav">
            <li><a class="nav-item nav-link" [routerLink]="['/home']" [routerLinkActive]="['active']">Home</a></li>
            <li><a class="nav-item nav-link" [routerLink]="['/about']" [routerLinkActive]="['active']">The Band</a></li>
            <li><a class="nav-item nav-link" [routerLink]="['/history']" [routerLinkActive]="['active']">History</a></li>
            <li><a class="nav-item nav-link" [routerLink]="['/press']" [routerLinkActive]="['active']">Press/Reviews</a></li>
            <li><a class="nav-item nav-link" [routerLink]="['/listen']" [routerLinkActive]="['active']">Listen</a></li>
            <!-- <li><a class="nav-item nav-link"  [routerLink]="['/demos']" [routerLinkActive]="['active']">Demos</a></li>-->
          </ul>

        </ul>
      </nav>

      <h6>Contact</h6>

      <div class="container"  >
        <p style="text-align: center;">Love and Fury  ©2023 &ndash; <a href="mailto:{{ lafEmail | replaceEmailAt}}"> Email Us!</a></p>
      </div>

    </div>
  </div>


</footer>
