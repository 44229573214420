<!--<script src="../../assets/js/wow.min.js"></script>-->
<div>
  <a [routerLink]="['/listen']" [routerLinkActive]="['active']">
    <img class="img-page-top-center" src="https://loveandfurymusic.com/uploads/images/band-slims-08-19-2019aaa.jpg"
         alt="Love and Fury - Listen to Planet"/></a>
</div>

<div id="{{pageId}}" class="container">
  <div class="content">

    <div class="fundUkraine" style="text-align: center;">
      <h6>TOGETHER MUSIC HELPS UKRAINE</h6>

      <p style="text-align: left;"><strong>Seattle band, Love and Fury has created a new song called “Berehynia
        Saves.”</strong>
        We are inviting you to help us support Ukrainians who are enduring an unimaginable hellscape
        since the brutal and unlawful invasion of the sovereign nation of Ukraine by Vladimir Putin and The Russian
        Federation.
      </p>

      <p>
        Berehynia Saves is a song collaboration between Gary King, James Stillion and Gretta Harley
        (with Kai Strandskov on drums and Angela Casteñada on background vocals).
      </p>

      <p>
        <strong>“Why do we do this to ourselves?”</strong> is a question asked by the Latvian poet James Stillion who
        Gary King asked to write a poem which became,
        “Berehynia Saves.” Gary and Gretta Harley used the words to compose this song. James’ question
        is a poignant testament to the complexities of human nature, where some wield power callously,
        disregarding the sanctity of human life. With our new song Love and Fury hopes to continue our
        mission to add an iota of something positive to these days of manufactured chaos. And now specifically to
        try to help Ukrainians who are enduring unimaginable suffering.
      </p>

      <p>TOGETHER we can help the people of Ukraine through donations to the respected Ukrainian organization,
        RAZOM.</p>

      <p>
        <span style="display: block;" class="small-text">photo credit AFP</span>
        <img src="assets/images/berehynia-statue.jpeg" alt="Berehynia Statue">
      </p>

      <p>

        <a href="https://www.razomforukraine.org/about-us" target="_blank" target="_blank">
          <img
            style="width: 100px;" src="assets/images/razom-logo-small.png" alt="razom logo"></a>
      </p>




      <p> To participate in our fundraising effort you can purchase "Berehynia Saves": <a href="https://loveandfury.bandcamp.com/track/berehynia-saves" target="_blank">  Love and Fury Bandcamp</a>.<br>
        80% of your donation (suggested $5 or more) will go to  <a href="https://www.razomforukraine.org/about-us" target="_blank" target="_blank">RAZOM</a>.
        The other 20% goes toward our expenses for producing and distributing the song.
      </p>



      <!--<p>
        Ukrainian children have been sent to re-education facilities inside Russia or the Russian-held areas of
        Ukraine under the pretext of "recovery." <a href="https://childrenofwar.gov.ua/en/" target="_blank">Children of War</a>.
      </p>-->
      <p><strong>Other ways you can help:</strong></p>

      <ol style="text-align: left;">
        <li>Spread the word by sharing the single on your social media platforms using the above links</li>
        <li>Ask your employer if they can match your donation </li>
        <li>Consider making an additional donation to RAZOM or another organization that helps the people of Ukraine</li>
      </ol>


      <h5>
        Here’s a little bit about Berehynia
      </h5>

      <p>
        Berehynia, believed to be the source of life and death, is a life-giving mother/goddess;
        the creator of heaven and all living things; and the mistress of heavenly water (rain) upon which the
        world relies for fertility and fruitfulness.
      </p>

      <p>
        Since Ukrainian independence in 1991 Berehynia has undergone a folkloric metamorphosis, and is today
        identified as a combination of the “hearth-mother” associated with the guardianship of the nation.

        Berehynia represents a place in Ukrainian nationalism, feminism, and neopaganism. The interpretation as a
        “protectress” a folk-etymology. is derived from the Ukrainian word bereh “river bank”, with the unrelated
        verb, berehty in Ukrainian which means “to protect”.
      </p>

      <p style="font-size: .7em;">references:<br>
        <a style="font-size: .7em;" href="https://tinyurl.com/bddebnc4" target="_blank">Bravery to be Ukraine</a><br>
        <a style="font-size: .7em" href="http://slavicchronicles.com" target="_blank">Slavic Chronicles</a>
      </p>

    </div>

    <!-- ****************************** -->
    <hr>
    <div class="row">

      <div class="col-sm-12 col-md-6 col-lg-6">

        <p>Love and Fury is a Seattle-based original rock band. Its wall of distorted, well-crafted guitars with
          psychedelic overtones are led by Gary King and Bob Watanabe. A tight, hard hitting and undulating rhythm
          section is held down by Jane Mabry Smith (bass) and Kai Strandskov (drums). Rob Knop (keys) flourishes the
          integrate arrangements; while Gretta Harley’s strong, haunting and melodious vocals paint lyrics about the
          days we live in. Love and Fury is just that. It rocks you in the face while offering you hope and beauty. We
          are nothing without you, listener and show goer. We can’t wait for you to hear the music.</p>

        <h3>Reviews</h3>

        <p>"Harley’s words deliver a potent condemnation of sexism and hypocrisy in poetic yet
          forceful fashion. “Unearth the lies, then face them down/expose you to the
          sirens/we’ll crowd you out, we’ll drown you out,” she sings. You won’t find a better
          example of the polar extremes of the band’s name being wrought in vivid, thrilling
          fashion."</p>

        <p>"King’s riffs oscillate pretty consistently between end-of-days power (the opener,
          “Scorpio”) and warbling psychedelic texture (“Kali”), but he’s a nimble songwriter
          whose compositions cast a wide net stylistically. “Ling” is a drama-infused, gothic
          piano ballad, while “Alchemy” throws down a forceful, slingshot bass and some
          near-U2 anthemic soaring. Like all the best rock records, Planet manages to be a
          consistent sum of its parts without sounding like ten iterations of the same
          song..."

          <br/>

          <a
            href="http://artisthome.org/seattle-rock-vets-unleash-love-and-fury/?fbclid=IwAR0wTb1gXRAloAITZ5WPzWX-0AhISWK2zwWJS3WW-nEer80fT9bnBpenz9Y"
            target="_blank">~ Tony Kay, Artist Home</a>
        </p>

      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">

        <div class="padding-20">
          <ul class="list-unstyled">
            <li>
              <img src="https://loveandfurymusic.com/uploads/images/gretta-stage2.jpg"
                   alt="Love and Fury - Listen to Planet"/>
            </li>
          </ul>
        </div>
      </div>


      <div class="container-fluid wow fadeIn">

        <div class="col-sm-12 col-md-4 col-lg-4 wow slideInLeft" data-wow-duration="1.0s" data-wow-delay="1.0s">
          <img class="wow fadeIn" data-wow-duration=".75s" data-wow-delay="0s"
               src="https://loveandfurymusic.com/uploads/images/gretta-jane.jpg"
               alt="Record Release Poster"/>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4 wow slideInLeft" data-wow-duration="1.0s" data-wow-delay="1.0s">
          <!--<h2 *ngIf="headingTwo">{{headingTwo}}</h2>-->
          <img class="wow fadeIn" data-wow-duration=".75s" data-wow-delay="0s"
               src="https://loveandfurymusic.com/uploads/images/gary1.jpg"
               alt="Gary - Love and Fury"/>
          <p></p>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4 wow slideInRight" data-wow-duration="1.0s" data-wow-delay="1.0s">
          <!--<h2 *ngIf="headingThree">{{headingThree}}</h2>-->
          <img class="wow fadeIn" data-wow-duration=".75s" data-wow-delay="0s"
               src="https://loveandfurymusic.com/uploads/images/bob1.jpg"
               alt="Bob - Love and Fury"/>
          <br/>
        </div>
      </div>


      <div class="container-fluid wow fadeIn">
        <div class="col-sm-12 col-md-4 col-lg-4 wow slideInLeft" data-wow-duration="1.0s" data-wow-delay="1.0s">
          <!--<h2 *ngIf="headingThree">{{headingThree}}</h2>-->
          <img class="wow fadeIn" data-wow-duration=".75s" data-wow-delay="0s"
               src="https://loveandfurymusic.com/uploads/images/jane1.jpg"
               alt="LIVE - Love and Fury"/>
          <br/>
        </div>


        <div class="col-sm-12 col-md-4 col-lg-4 wow slideInUp" data-wow-duration="1.0s" data-wow-delay="1.0s">
          <!--<h2 *ngIf="headingTwo">{{headingTwo}}</h2>-->
          <img class="wow fadeIn" data-wow-duration=".75s" data-wow-delay="0s"
               src="https://loveandfurymusic.com/uploads/images/kai2.jpg"
               alt="Kai - Love and Fury"/>
          <br/>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4 wow slideInRight" data-wow-duration="1.0s" data-wow-delay="1.0s">
          <!--<h2 *ngIf="headingThree">{{headingThree}}</h2>-->
          <img class="wow fadeIn" data-wow-duration=".75s" data-wow-delay="0s"
               src="https://loveandfurymusic.com/uploads/images/rob-knop-at-piano.jpg"
               alt="Rob - Love and Fury"/>

        </div>
        <br/>

      </div>


      <!-- MailChimp -->
      <div class="container" style="max-width: 500px;">

        <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css"
              rel="stylesheet"
              type="text/css">
        <style type="text/css">
          #mc_embed_signup {
            background: #d76000;
            color: #ffffff;
            clear: left;
            font: 14px Helvetica, Arial, sans-serif;
            width: 100%;
          }

          /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
             We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
        </style>
        <div id="mc_embed_signup">
          <form
            action="https://loveandfurymusic.us19.list-manage.com/subscribe/post?u=acfff7754254a0672714df83c&amp;id=d4d894c15a"
            method="post" id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="validate"
            target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">
              <label for="mce-EMAIL" style="color: #fff;">Add me to your mailing
                list</label>
              <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL"
                     placeholder="email address"
                     required>
              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px;" aria-hidden="true">
                <input type="text"
                       name="b_acfff7754254a0672714df83c_d4d894c15a"
                       tabindex="-1"
                       value="">
              </div>
              <div class="clear"><input type="submit" value="Subscribe"
                                        name="subscribe"
                                        id="mc-embedded-subscribe" class="button">
              </div>
            </div>
          </form>
        </div>
      </div><!-- end MailChimp -->


      <div class="container row bg-orange wow fadeIn" data-wow-delay=".5s">

        <div class="col">
          <div class="small-text">Photos by: Andrew Kvenvolden</div>

          <div class="small-text">Website: Gary King</div>

          <br/><br/><br/>
        </div><!-- end col -->

        <div class="col-12">
          <br/>
          <!--<img src="https://loveandfurymusic.com/uploads/images/tshirt-mens.jpg"
               alt="Love and Fury" style="max-width: 400px;"/>-->

        </div>


      </div><!--end bg-orange -->


    </div>

  </div>

</div>
