<div class="wow fadeIn">
  <div class="col-sm-12 col-md-12 col-lg-12 wow fadeIn" data-wow-duration="1.0s" data-wow-delay="1.750s">
    <!--<h2 *ngIf="headingThree">{{headingThree}}</h2>-->
    <img class="wow fadeIn" data-wow-duration=".75s" data-wow-delay="0s"
         src="https://loveandfurymusic.com/uploads/images/band.jpg"
         alt="LIVE - Love and Fury"/>
    <br/>
    <span class="small-text">Photo: David Larew</span>
  </div>

  <br/>
</div>

<div id="history" class="container">

  <div class="col-sm-129 col-md-9 col-lg-9">
    <section>

      <h1>History</h1>
      <h3>Over the course of several years</h3>

      <p>Gary King wrote and recorded dozens of instrumental songs. He arranged and played all of the instruments
        himself except for a few drum tracks that were laid down by friends in informal settings, or MIDI tracks. Gary
        reached out to Gretta in the late winter of 2017 about writing lyrics, and when Gretta heard Gary's music she
        was blown away. Inspired by the new political climate of America, the words flew out and vocal melodies came
        naturally. Gary and Gretta quicky found themselves as kindred spirits and they embarked on recording her vocals.
        Soon it was evident that a drummer was needed to give a cohesive drum sound to the the recordings. Kai
        Strandskov came in and crushed it.
      </p>
      <h3>Initially intended as a recording project</h3>
      <p>Gary and Gretta got increasingly enthused about making it better. They even joked about how much fun it would
        be if these songs were performed live. But the joke turned into a craving and their humble recording project
        took on a life of its own. They continued to fine tune the recordings and Gary busily worked away in the studio.
      </p>

      <h3>The music wanted a band</h3>
      <div>Kai was in. Badass multi-instrumentalists and singer, Jane Mabry Smith entered the picture after hearing
        Gretta perform some of these songs acoustically at a house concert. Gary and Gretta asked Jane to lay down a few
        tracks, replacing some of the ghost bass lines. We were thrilled to have Amy Denio come in and lay down some
        saxophone. Since the recording, Love and Fury, the live band was born. In addition to Kai and Jane, Bob Watanabe
        plays guitar with Gary, and Rob Knob plays keyboard.
      </div>

      <div>Love and Fury is a tour de force of rock, with subtle and overt messages of hope and love. We hope you like
        it.
      </div>
      <div>Music by Gary King ©2019 Gary King Audio, ASCAP<br/>
        Lyrics by Gretta Harley ©2019 Pas Palapatas, ASCAP
      </div>
    </section>
   <!-- <div class=\"cycle-slideshow\"
         data-cycle-fx=carousel
         data-cycle-timeout=1000
         data-cycle-carousel-visible=2
         data-cycle-carousel-fluid=true>
      <img src=\"http://loveandfurymusic.com/uploads/images/gretta-avast-console.jpg\">
      <img src=\"http://loveandfurymusic.com/uploads/images/j100-love-and-fury.jpg\">\n
    </div>-->

  </div>


