<app-header></app-header>
<app-nav></app-nav>

<router-outlet></router-outlet>

<div style="display: none;" class="social-icons hidden-sm wow slideInRight transparent-09" data-wow-duration="1s" data-wow-delay="0.5s">

  <a href="https://loveandfury.bandcamp.com" target="_blank">
    <span class="icon fab fa-bandcamp"></span> Bandcamp (Buy)</a>

  <a href="https://www.facebook.com/loveandfury/" target="_blank">
    <span class="icon fab fa-facebook-f"></span> Facebook</a>

  <!-- Instagram -->
  <a href="https://www.instagram.com/loveandfuryband/" target="_blank">
    <span class="icon fab fa-instagram"></span> Instagram</a>

  <!-- twitter -->
  <a target="_blank" href="https://twitter.com/FuryBand" target="_blank">
    <span class="icon fab fa-twitter"></span> Twitter</a>

  <!-- facebook Share-->
  <span class="fb-share-button" data-href="https://loveandfurymusic.com"
        data-layout="button"
        data-size="large"
        data-mobile-iframe="true">
                        <a target="_blank"
                           href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Floveandfurymusic.com%2F&amp;src=sdkpreparse"
                           class="fb-xfbml-parse-ignore"> <span
                          class="icon fas fa-share"></span> Share</a></span>
  <!--<div class="social-listen">LISTEN</div>-->
  <!-- Youtube --><a href="https://www.youtube.com/channel/UC4Z5_SPQouCipTpMVbZ4mfA"
                     aria-hidden="false" target="_blank">
  <span class="icon fab fa-youtube"></span> YouTube</a>

  <!-- Spotify -->
  <a target="_blank"
     href="https://open.spotify.com/artist/5qQmHtMxkMseseXwcwo8d0"
     target="_blank">
    <span class="icon fab fa-spotify"></span> Spotify</a>

  <!-- Apple Music -->
  <a href="https://music.apple.com/us/album/planet/1452080684" target="_blank">
    <span class="icon fab fa-apple"></span> Apple Music  (Buy)</a>

  <!-- Amazon -->
  <a target="_blank"
     href="https://www.amazon.com/Planet-Love-Fury/dp/B07N58ZYYH/ref=sr_1_2?s=music&ie=UTF8&qid=1550439090&sr=8-2&keywords=love+and+fury+planet"
     target="_blank">
    <span class="icon fab fa-amazon"></span> Amazon (Buy)</a>

  <!-- CD Baby -->
  <!-- <a href="https://store.cdbaby.com/cd/loveandfury2" target="_blank">
       <span class="icon fas fa-cloud-download-alt"></span> CD Baby (Buy)</a>-->

</div>

<!--<div class="social-icons hidden-lg hidden-md wow " data-wow-duration="1s" data-wow-delay="0.5s"
     style="position: relative; bottom: 0;">

    &lt;!&ndash; Links &ndash;&gt;

    <h3 class="text-center ">Links</h3>

            <a href="https://www.youtube.com/channel/UC4Z5_SPQouCipTpMVbZ4mfA"
                aria-hidden="false" target="_blank"><i
                class="fab fa-youtube"></i> YouTube</a>

            <a href="https://www.facebook.com/loveandfury/" target="_blank">
                <i class="fab fa-facebook-f"></i> Facebook</a>

            <a href="https://www.instagram.com/loveandfuryband/" target="_blank">
                <i class="fab fa-instagram"></i> Instagram</a>

            <a target="_blank" href="https://twitter.com/FuryBand" target="_blank">
                <i class="fab fa-twitter"></i> Twitter</a>

            <a target="_blank"
               href="https://open.spotify.com/artist/5qQmHtMxkMseseXwcwo8d0"
               target="_blank">
                <i class="fab fa-spotify"></i> Spotify</a>

            <a target="_blank"
               href="https://www.amazon.com/Planet-Love-Fury/dp/B07N58ZYYH/ref=sr_1_2?s=music&ie=UTF8&qid=1550439090&sr=8-2&keywords=love+and+fury+planet"
               target="_blank">
                <i class="fab fa-amazon"></i> Amazon Music</a>

            <span class="fb-share-button" data-href="http://loveandfurymusic.com"
                  data-layout="button"
                  data-size="large"
                  data-mobile-iframe="true">
                        <a target="_blank"
                           href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Floveandfurymusic.com%2F&amp;src=sdkpreparse"
                           class="fb-xfbml-parse-ignore"> <i
                                class="fas fa-share"></i> Share this page on Facebook</a></span>

            <a href="https://store.cdbaby.com/cd/loveandfury2" target="_blank">
                <i class="fas fa-cloud-download-alt"></i> Purchase our album</a>

</div>-->
<!--<app-merch></app-merch>-->
<app-footer></app-footer>

