<div id="specs">

  <section class="container">

    <div class="col-sm-12 col-md-6 col-lg-6">
      <h1>Specs</h1>

      <ul class="list-unstyled">

        <li class="bold">Vocals (2 mono inputs)</li>
        <li>
          <ul>
            <li class="bold">Gretta Harley</li>
            <li class="bold">Jane Mabry Smith</li>
          </ul>
        </li>


        <li class="bold">Guitars (2 mono inputs )</li>
        <ul>
          <li class="bold">Gary King</li>
          <li>1 x 12 cabinet</li>
          <li class="bold">Bob Watanabe</li>
          <li>2 x 12 cabinet</li>
        </ul>


        <li class="bold">Bass (2 mono inputs)</li>
        <li>
          <ul>
            <li class="bold">Jane Mabry Smith</li>
            <li>4 x 10 cabinet</li>
            <li>DI</li>
          </ul>
        </li>


        <li class="bold">Keyboards (2 stereo inputs, 1 mono input)</li>
        <li>
          <ul>
            <li class="bold">Rob Knop</li>
            <li>Korg Sampler (stereo)</li>
            <li>Kurzweil Piano (stereo)</li>
          </ul>

        </li>

      </ul>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-6">
      <img src="https://loveandfurymusic.com/uploads/images/stage-plot.jpg" alt="stage plot"/>
    </div>


  </section>

</div>
